<template>
	<v-card class="login-view">
		<v-card-title class="login-header">
			<div class="roundup-logo"></div>
		</v-card-title>
		<v-card-text>
			<div class="login-content">
				<v-form v-on:submit.prevent="onSubmit">
					<ui-label>
						<ui-input v-model="email" placeholder="Email" />
					</ui-label>
					<ui-label>
						<ui-input
							v-model="password"
							placeholder="Password"
							type="password"
						/>
					</ui-label>
					<ui-label>
						<Roundup-Button
							color="primary"
							native-type="submit"
							:loading="isLoading"
							class="mt-2"
							>Login</Roundup-Button
						>
					</ui-label>
					<div class="forgot-password">
						<router-link :to="{ name: 'forgot-password' }"
							>Forgot Password?</router-link
						>
					</div>
					<div class="forgot-password">
						<router-link :to="{ name: 'register' }"
							>Register Your Organization</router-link
						>
					</div>
				</v-form>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Screen from '../../ui/Screen'
import UiButton from '../../ui/Button'
import UiInput from '../../ui/Input'
import UiLabel from '../../ui/Label'
import store from '../../../store'

export default {
	name: 'login-view',
	components: { UiButton, UiInput, UiLabel, Screen },
	data() {
		return {
			isLoading: false,
			email: '',
			password: '',
			stripeAccounts: process.env.VUE_APP_stripeFallbackAccounts.split(
				','
			)
		}
	},
	methods: {
		...mapActions(['login', 'getInstitution']),
		...mapGetters(['isOnboarding', 'onboardingStep']),
		onSubmit() {
			this.isLoading = true
			this.login({ email: this.email, password: this.password })
				.then(data => {
					this.isLoading = false
					if (!data.institution) {
						this.$router.push({ name: 'create-organization' })
						return
					}
					if (store.getters.hasStripe === true) {
						this.$router.push({ name: 'home' })
						return
					}
					if (store.getters.onboardingStep < 5) {
						this.$router.push({ name: 'bank-account' })
						return
					}
					if (!data.institution.launch_date) {
						this.$router.push({ name: 'home' })
						// eslint-disable-next-line
						return
					} else {
						this.$router.push({ name: 'home' })
						// eslint-disable-next-line
						return
					}
				})
				.catch(({ message }) => {
					this.isLoading = false
					this.setAlert({
						alertText: message,
						showAlert: true,
						alertColor: 'error'
					})
				})
		}
	}
}
</script>

<style lang="scss">
@import '../../../styles/variables';

.login-view {
	text-align: left;
	min-width: 300px;
	max-width: 460px !important;
	margin: auto;
	padding: 10px !important;
	.login-header {
		p {
			margin: 0px;
		}
	}
	.roundup-logo {
		background: url('../../../assets/roundup_logo.svg');
		background-size: 137px 25px;
		background-repeat: no-repeat;
		background-position: center;
		width: 100%;
		height: 14px;
		margin: 0px 0px 20px;
	}
	.forgot-password {
		font-size: 13px;
	}
}
</style>
